* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  background-color: var(--bg-primary);
}

a {
  text-decoration: none;
  color: var(--content-primary);
}

ul {
  list-style: none;
}

ul li {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

main {
  margin-top: 57px;
  margin-bottom: 64px;
}

.w-full {
  width: 100%;
}

.container {
  width: 1366px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 83px;
} 

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-color-white {
  color: #fff !important;
}

.font-weight-bold {
  font-weight: bold;
}

.pl-0 {
  padding-left: 0 !important;
}


.text-color-content-inverse-primary {
  color: var(--content-inverse-primary) !important;
}

.text-color-content-always-light-primary {
  color: var(--content-always-light-primary) !important;
}

.text-color-content-primary {
  color: var(--content-primary) !important;
}

.text-color-content-secondary {
  color: var(--content-secondary) !important;
}

.text-color-content-accent-primary {
  color: var(--content-accent-primary) !important;
}

.text-color-content-link-primary {
  color: var(--content-link-primary) !important;
}

.d-flex {
  display: flex;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.wrapper {
  background: url(../assets/bg-main.png);
  background-size: 100%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.portal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  display: none;
}

.portal_blur {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  background-color: var(--bg-scrimPrimary);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.portal_container {
  background-color: var(--bg-primary);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px 16px 0px 0px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  .portal_options {
    position: relative;
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;

    li {
      cursor: pointer;
    }
  }
}


.milestone  {
  margin-top: 92px;
}

.bg-secondary {
  background-color: var(--bg-secondary);
}

.row {
  display: flex;
  justify-content: space-between;
}

.careerDetail_action {
  display: flex;
  justify-content: flex-end;
}

.border-bottom {
  border-bottom: 1px solid var(--line-primary);
}

.round-16 {
  border-radius: 16px;
}

.round-0 {
  border-radius: 0;
}
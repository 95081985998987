.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-96 {
  margin-top: 96px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-12 {
  margin-bottom: 12px;
}


/* === start tablet === */
@media (max-width: 1180px) { }

/* === start tablet small === */
@media (max-width: 980px) {
  .mt-64 {
    margin-top: 24px;
  }
  .mt-70 {
    margin-top: 56px;
  }
}

/* === start mobile === */
@media (max-width: 640px) { }

@media (max-width: 320px) { }
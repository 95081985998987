.buttonSimple {
  background-color: var(--bg-accent-primary);
  border-radius: 8px;
  padding: 14px 16px;
  outline: 0;
  border: 0;
  cursor: pointer;
  h6 {
    color: #fff;
  }
}
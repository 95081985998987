.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  padding: 10px 83px;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--line-primary);
  z-index: 3;
}

.header_blur {
  opacity: 0;
  z-index: -1;
}

.header_iconMenu {
  display: none;
}

.header_logo {
  width: 122px;
  height: 36px;
  flex-shrink: 0;
}

.header_menu {
  transition: all .3s ease-in-out;
}

.header_menu_nav {
  ul {
    display: flex;

    li {
      & + li {
        padding-left: 16px;
      }
    }
  }
}

.header_menu_border {
  border-left: 1px solid var(--line-primary);
  padding-left: 0 !important; 
  margin-left: 16px;
}
.career_banner {
  background: url(../assets/career-path/banner-career.png) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 49px;
}

.careerLife_info {
  margin-right: 35px;
  h5 {
    width: 525px;
  }
}

.careerSearch {
  background-color: var(--bg-elevation);
  box-shadow: 0px 4px 8px var(--s3-top);
  border-radius: 16px;
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 26px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.careerSearch_position {
  display: flex;
  align-items: center;
  flex-grow: 1;

  input {
    width: 100%;
    height: 28px;
    background-color: transparent;
    border: 0;
    outline: 0;
    margin: 0 18px;
  }

  svg {
    cursor: pointer;
  }
}

.careerSearch_select {
  border-left: 1px solid var(--line-primary);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 30px;
  margin-right: -64px;
  &.location {
    margin-right: -16px;
  }
}

.careerOpens {
  margin-bottom: 166px;
}

.careerList {
  --careerList-spacing: 16px;
  --careerList-column: 2;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--careerList-spacing)*-1);
  margin-bottom: calc(var(--careerList-spacing)*-1);
}

.careerBox_item {
  width: calc(100%/var(--careerList-column) - var(--careerList-spacing));
  margin-left: var(--careerList-spacing);
  margin-bottom: calc(var(--careerList-spacing) + 4px);
}

.careerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.careerBox_border {
  border: 1px solid var(--line-primary);
  padding: 16px 26px;
}

.careerBox_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.careerBox_info {
  padding-left: 24px;
}

.careerDetail_content {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px 24px;
  margin-top: 16px;

  h5 {
    font-family: "SVN-Poppins-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--content-primary);
  }

  &.applying {
    background-color: var(--bg-primary);
    border: 0.5px solid var(--line-primary);
    box-shadow: 0px 4px 8px var(--s3-top);
  }
}

.careerDetail_lead {
  list-style: disc;
  margin-left: 24px;
  margin-top: 4px;
}

.careerDetail_wrapper {
  display: flex;
}

.careerDetail_left {
  flex-grow: 1;
  width: 100%;
}

.careerDetail_right {
  width: 320px;
  margin-left: 20px;

  .careerBox {
    padding: 14px 12px;
  }
}

.careerDetail_info {
  margin-top: 16px;
}
@import "scss/theme";
@import "scss/margin";
@import "scss/typography";
@import "scss/common";
@import "scss/header";
@import "scss/footer";
@import "scss/button";
@import "scss/landingpage";
@import "scss/career";
@import "scss/field";
@import "scss/dark_mode";
@import "scss/responsive";

@font-face {
  font-family: "SVN-Poppins-ExtraBold";
  src: url("assets/fonts/SVN-Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "SVN-Poppins-Bold";
  src: url("assets/fonts/SVN-Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SVN-Poppins-Regular";
  src: url("assets/fonts/SVN-Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SVN-Poppins-Black";
  src: url("assets/fonts/SVN-Poppins-Black.ttf") format("truetype");
}


footer {
  background-color: var(--content-always-dark-primary);
  padding: 19px 0;
}

.footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  
  a {
    & + a {
      margin-left: 16px;
    }
  };
}

.footerSocial_line {
  border-bottom: 1px solid var(--content-always-light-primary);
  opacity: 0.1;
  margin-top: 18px;
  margin-bottom: 32px;
}

.footerMenu {
  display: flex;
  justify-content: space-between;
}

.footerMenu_item {
  ul {
    margin-top: 12px;

    li {
      & + li {
        margin-top: 8px;
      }
    }
  }
}

.footer_copyright {
  text-align: center;
  margin-top: -19px;
}
.themeControl {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 0 2px;
  display: flex;
  align-items: center;
  height: 32px;;
}

.themeControl_box {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  & + & {
    margin-left: 8px;
  }

  svg {
    path {
      fill: var(--content-primary);
    }
  }

  &.active {
    background: var(--bg-accent-primary);
    border-radius: 6px;
    
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.empowering {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 125px;
  margin-bottom: 134px;
}

.empowering_creator {
  width: 560px;
  h5 {
    margin: 20px 0;
  }
}

.empowering_banner {
  width: 420px;
}

.creators {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creators_top {
  text-align: center;
}

.creators_content {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.creators_values {
  text-align: center;
  width: 33.33%;

  & + & {
    margin-left: 24px;
  }

  img {
    width: 256px;
  }
}

.creators_bg_gradient {
  background: linear-gradient(180deg, rgba(79, 149, 255, 0.06) 0%, rgba(79, 149, 255, 0) 100%);
  border-radius: 16px;
}


.developers_lead {
  background: linear-gradient(180deg, #17E1FF 0%, rgba(103, 235, 255, 0) 85.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 10px;
}

.developers {
  margin-top: 128px;
}

.developers_banner {
  width: 604px;
}

.developers_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.developers_des {
  width: 460px;
  margin-top: -185px;
}

.artist {
  background: url(../assets/artist-bg.png) no-repeat center center;
  background-size: 100% 100%;
  margin-top: -355px;
  height: 1164px;
}

.artist_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 393px;
}

.artist_banner {
  width: 632px;
  position: relative;
  top: -70px;
}

.artist_des {
  width: 460px;
  margin-top: -110px;
}

.community {
  margin-top: -135px;
}

.community_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.community_banner {
  width: 640px;

  img {
    position: relative;
    left: -80px;
  }
}

.community_des {
  width: 460px;
}

.compare {
  margin: 128px 0;
}

.compareTable {
  overflow: auto;
  table {
    width: 100%;
    border: 1px solid var(--line-primary);
    border-radius: 8px;

    tr {
      th {
        border-left: 1px solid var(--line-primary);
        border-bottom: 1px solid var(--line-primary);
        min-width: 180px;
        height: 64px;
        &:first-child {
          width: 100%;
          padding-left: 12px;
          border-left: 0;
        }
      }
      td {
        border-left: 1px solid var(--line-primary);
        border-bottom: 1px solid var(--line-primary);
        text-align: center;
        height: 64px;

        &:first-child {
          width: 100%;
          text-align: left;
          padding-left: 12px;
          border-left: 0;
        }
      }
      .is-active {
        background-color: var(--bg-terny-primary);
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    
    .border-left {
      border-left: 1px solid var(--line-primary) !important;
    }
  }
}

.compareTable_chain {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding-top: 10px;

  h6 {
    margin-top: -7px;
    margin-left: 4px;
  }

  img {
    width: 48px;
  }
}

.dual {
  margin-bottom: 128px;
}

.dualToken {
  display: flex;
  justify-content: space-between;
}

.dualToken_item {
  box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.03);
  border-radius: 16px;  
  padding: 32px;
  width: 48.5%;

  h3 {
    margin-top: -12px;
  }

  img {
    width: 104px;
    height: 104px;
    margin-left: -13px;
  }

  &.ichor {
    background: linear-gradient(314.75deg, #FFC582 25.11%, #F3E7DB 100%);
  }

  &.ambros {
    background: linear-gradient(314.75deg, #89A7FF 25.11%, #DFEDF8 100%);
  }
}

.dualToken_line {
  border-top: 1px solid var(--content-inverse-primary);
  opacity: .3;
  margin: 16px 0;
}

.roadmap {
  margin-bottom: 64px;
}

.roadmap_content {
  background: linear-gradient(180deg, #4169E1 0%, #9D54FA 50%, #4169E1 100%);
  box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 64px;
  display: flex;
  justify-content: space-between;
}

.roadmap_item {
  width: 20%;

  &:last-child {
    width: 22%;
  }

  & + & {
    margin-left: 32px;
  }

  svg {
    width: 180px;
    margin-left: -27px;
  }

  ul {
    list-style: disc;
    margin-left: 18px;
    margin-top: 16px;
  }
}

.leading {
  margin-bottom: 128px;
}

.leading_item {
  background-color: var(--content-always-light-primary);
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
  border-radius: 16px;  
  padding: 22px 33px;
  text-align: center;

  img {
    width: 154px;
    height: 116px;
    object-fit: contain;
  }
}

.leadingContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.leading_item {
  width: 19%;
  margin-bottom: 24px;
}


.creators_bg {
  background: url(../assets//bg-keyfeature.png) no-repeat top left;
  background-size: 100%;
  width: 100%;
  height: 108px;
  margin-bottom: 90px;
}

.subcribe {
  border: 2px solid var(--line-primary);
  border-radius: 16px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
}

.subcribe_letter {
  width: 376px;
  flex-shrink: 0;
  margin-left: 40px;
}

.subcribe_field {
  border: 1px solid var(--line-primary);
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  background-color: var(--bg-elevation);
  padding: 8px;
}

.subcribe_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.subcribe_input {
  width: 100%;
  input {
    outline: 0;
    background-color: transparent;
    border: 0;
    width: 100%;
    padding: 8px 16px;
  }
}

.subcribe_checkbox {
  margin: 16px 0;

  .MuiCheckbox-root {
    color: var(--content-primary);
  }
}

.subcribe_info_letter {
  display: none;
  width: 228px;
  margin-top: 16px;
}
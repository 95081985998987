/* === start tablet === */
@media (max-width: 1180px) {
  html {
    overflow-x: hidden;
  }

  .container {
    padding: 0 16px;
  }

  .header {
    padding: 16px 16px 30px;
    flex-direction: column;
    height: 66px;
  }

  .header_logo {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .header_menu_border {
    display: none;
  }

  .header_iconMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--bg-secondary);
    transition: all .3s ease-in-out;
    position: relative;

    &::before,
    &::after,
    .header_iconMenu_line {
      content: "";
      display: block;
      transition: all 0.3s ease-in-out;
      width: 18px;
      height: 2px;
      border-radius: 1px;
      background-color: var(--content-primary);
    }

    .header_iconMenu_line {
      margin: 5px 0;
    }
  
    &.is-show {
      // background-color: var(--content-primary);
      .header_iconMenu_line {
        opacity: 0;
      }
  
      &::before {
        transform: rotate(45deg);
        position: absolute;
      }
      &::after {
        transform: rotate(-45deg);
        position: absolute;
      }

      &::before,
      &::after {
        top: 19px;
      }
    }
  }

  .header_blur {
    background-color: var(--bg-scrimPrimary);
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;

    &.is-show {
      opacity: 1;
      z-index: 2;
    }
  }

  .header_menu {
    margin-top: 18px;
    transform: translateX(-200%);
    width: 100%;
  }

  .header_menu_nav {
    ul {
      display: block;

      li {
        & + li {
          padding-left: 0;
          padding-top: 28px;
        }
      }
    }
  }

  .header_theme {
    position: absolute;
    right: 75px;
    margin-top: -2px;
  }


  .header_mobile {
    height: auto;
    .header_menu {
      transform: translateX(0);
    }
  }

  .themeControl {
    height: 40px;
  }
}

/* === start tablet small === */
@media (max-width: 980px) {
  .empowering {
    flex-direction: column;
    margin-bottom: 80px;
  }

  .empowering_creator {
    text-align: center;
    width: 320px;
    max-width: 100%;
  }

  .buttonSimple {
    h6 {
      line-height: 20px;
      font-size: 14px;
    }
  }

  .empowering_banner {
    width: 300px;
    max-width: 100%;
    margin-top: 24px;
  }

  .creators_top {
    text-align: center;
    width: 320px;
    max-width: 100%;

    h5 {
      width: 250px;
      margin: 0 auto;
    }
  }

  .creators_values {
    img {
      width: 160px;
    }
  }

  .developers {
    margin-top: 96px
  }

  .developers_content {
    flex-direction: column;
  }
  
  .developers_banner {
    max-width: 280px;
  }

  .developers_des {
    margin-top: 0;
    max-width: 342px;
    text-align: center;

    h3 {
      margin-bottom: 4px;
    }
  }

  .artist_content {
    flex-direction: column-reverse;
    padding-top: 180px;
    padding-bottom: 80px;
  }

  .artist_banner {
    max-width: 293px;
    top: -20px;
  }

  .artist_des {
    text-align: center;
    margin-top: 0;
    width: 500px;
    max-width: 100%;

    h3 {
      margin-bottom: 4px;
    }
  }

  .artist {
    background-image: url(../assets/artist-bg-mobile.png);
    margin-top: -50px;
    height: auto;
  }

  .community {
    margin-top: 28px;
  }

  .community_content {
    flex-direction: column;
  }

  .community_banner {
    max-width: 297px;
    
    img {
      left: 0;
    }
  }

  .community_des {
    max-width: 360px;
    text-align: center;
    
    h3 {
      margin-bottom: 4px;
    }
  }

  .mt-8 {
    margin-top: 4px;
  }

  .mt-96 {
    margin-top: 54px;
  }

  .compare {
    margin-top: 96px;
  }

  .compareTable {
    table {
      tr {
        th {
          min-width: 135px;
        }
        td {
          height: 47px;
        }
      }
    }
  }

  .dual {
    margin-bottom: 96px;
    h4 {
      max-width: 355px;
      margin: 8px auto 0;
    }
  }

  .roadmap {
    margin-bottom: 96px;
  }
  

  .roadmap_content {
    flex-direction: column;
    padding: 40px 24px 24px;
  }

  .roadmap_item {
    width: 100%;
    padding-left: 96px;

    & + & {
      margin-left: 0;
      margin-top: 45px;
    }

    &:last-child {
      width: 100%;
      margin-bottom: 24px;
    }

    h5 {
      margin-top: -7px;
    }

    svg {
      transform: rotate(90deg);
      position: absolute;
      left: 0;
      margin-top: 28px;
      margin-left: 0;
    }
  }

  .leading {
    margin-bottom: 96px;
  }

  .leading_item {
    width: 32%;
    padding: 0 8px;
  }

  .dualToken_item  {
    h5 {
      font-family: 'SVN-Poppins-Regular';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .subcribe {
    padding: 16px;
  }

  .subcribe_letter {
    display: none;
  }

  .subcribe_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .subcribe_info_letter {
    display: block;
  }

  .subcribe_heading {
    span {
      display: none;
    }
  }

  .careerLife {
    flex-direction: column-reverse;
  }

  .careerLife_info {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    margin-right: 0;

    h3 {
      font-size: 40px;
    }

    h5 {
      width: 100%;
    }
  }

  .career_heading{
    text-align: center;
    margin-bottom: 24px;
  }


  .careerSearch_position {
    flex-grow: 0;
    margin-right: 18px;
    input {
      display: none;
    }
  }

  .careerSearch{
    .selectDefaultScss {
      width: 100%;
    }
    &.openSearch {
      .careerSearch_position {
        width: 100%;
        input {
          display: block;
          width: 100%;
        }
      }
      .careerSearch_select {
        display: none;
      }
    }
  }

  .careerSearch_select {
    flex-grow: 1;
  }

  .careerList {
    --careerList-column: 1;
  }

  .careerBox_container {
    img {
      width: 48px;
    }
  }

  .careerBox_info {
    h4 {
      font-size: 16px;
    }
  }

  .careerBox_arrow {
    width: 18px;
  }

  .careerOpens {
    margin-bottom: 80px;
  }

  .creators_bg {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .developers_lead {
    margin-bottom: 55px;
  }
 
}

/* === start mobile === */
@media (max-width: 640px) {
  .creators_content {
    flex-direction: column;
  }

  .creators_values {
    width: 100%;

    & + & {
      margin-left: 0;
    }
  }

  .creators_bg_gradient {
    & + & {
      margin-top: 24px;
    }
  }

  .compareTable {
    table {
      width: 930px;
    }
  }

  .dualToken {
    flex-direction: column;
    margin-top: 24px;
  }

  .dualToken_item {
    width: 100%;

    img {
      width: 64px;
      height: 64px;
      margin-right: 12px;
    }

    & + & {
      margin-top: 16px;
    }
  }

  .dualToken_top {
    display: flex;
    align-items: center;
  }

  .footerMenu {
    flex-direction: column;
  }

  .footerMenu_item {
    & + & {
      margin-top: 24px;
    }
  }

  .roadmap_content {
    padding-bottom: 0px;
  }

  .career_banner {
    height: 260px;
    padding-bottom: 8px;

    h1 {
      font-size: 39px;
    }
  }
  main {
    margin-top: 66px;
  }

  .selectDefaultScss {
    ul {
      display: none;
    }
  }

  .portal {
    display: flex;
  }

  .careerDetail_right {
    display: none;
  }

  .careerDetail_info {
    margin-top: 24px;
  }

  .careerDetail_action {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    margin-top: 0;
    border-radius: 0;
    padding: 8px 16px 40px;
    background-color: var(--bg-primary);
    border: 0.5px solid var(--line-primary);
    box-shadow: 0px 4px 8px var(--s3-top);

    .buttonSimple {
      min-width: 100% !important;
    }
  }
}


@media (max-width: 414px) {
  .career_banner {
    height: 180px;
  }
}


@media (max-width: 360px) {
  .careerSearch {
    zoom: 0.8;
  }
}


@media (max-width: 320px) {
  #root {
    zoom: 0.9;
  }
}